@use '~@angular/material' as mat;

@include mat.core();

$primary-color: #4489B1;
$primary-light-color: #6199BB;
$primary-inverse-color: #FFFFFF;
$secondary-color: rgba(0, 0, 0, 0.6);
$max-sm-width: 767px;

$app-primary: mat.define-palette(mat.$cyan-palette);
$app-accent:  mat.define-palette(mat.$cyan-palette);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

html, body, .viewport {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    color: $primary-color;
    background-color: $primary-inverse-color;
}

div, span {
    font-family: 'Roboto Light', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: normal;
}

.container {
  display: block;
}

.flex-container {
  @extend .container;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

.contrast-flex-container {
  @extend .flex-container;
  background-color: $primary-color;
  color: $primary-inverse-color;
  align-items: center;
}

.full-width {
    width: 100%;
}